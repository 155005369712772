.Footer {
  background: var(--color-subtle-light);
  width: 100%;
  padding: 3rem 0;
  margin-top: 5.5rem;
  display: flex;
  justify-content: center;
}

.Footer ul {
  padding: 0;
  padding-top: 2rem;
  color: var(--color-subtle-dark);
  margin: 0;
}

.Footer li {
  display: inline;
}

.Footer li:not(:last-child) {
  margin-right: 1rem;
}

.Footer li:not(:last-child):after {
  content: "\B7";
  margin-left: 1rem;
}
